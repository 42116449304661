* {
  margin: 0%;
  padding: 0%;
}

body {
  margin: 0%;
  padding: 0%;
}

.main_order_detail_div {
  width: 100%;
  padding: 2rem 1rem;
  background-color: white;
  box-shadow: 0px 0px 5px lightgray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.main_order_detail_div .react-tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.main_order_detail_div .react-tabs__tab-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  border: none;
}

.main_order_detail_div .react-tabs__tab {
  width: 200px;
  /* background-color: #0A74B9;
    color: white; */
  background-color: white;
  color: black;
  box-shadow: inset 0px 0px 5px transparent;
  border-radius: 10px;
  box-shadow: 2px 2px 5px lightgray, -2px -2px 5px lightgray;
}

.main_order_detail_div .react-tabs__tab--selected {
  border: none;
  /* background-color: white;
    color: black; */
  background-color: #0a74b9;
  color: white;
  box-shadow: inset 0px 0px 5px lightgray;
}

.main_order_detail_div .react-tabs__tab-panel--selected {
  width: 100%;
}

.order_detail_tab_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.order_detail_tab_div div img {
  width: 2rem;
}

.view_order_detail_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.view_order_detail_inner_header_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 5px lightgray;
  border-radius: 10px;
  padding: 1rem 2rem;
}

.view_order_detail_inner_header_left_div {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.view_order_detail_inner_header_left_headind_div {
  font-size: 1.5rem;
  font-weight: bold;
}

.view_order_detail_inner_header_left_text_div {
  font-size: 1rem;
  color: gray;
}

.view_order_detail_inner_header_left_price_div {
  font-size: 1.3rem;
  font-weight: 600;
  color: gray;
}

.view_order_detail_inner_header_right_div {
  width: 13%;
  background-color: lightgray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.view_order_detail_inner_header_right_div img {
  width: 70%;
}

.view_order_detail_inner_body_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.view_order_detail_inner_body_inner_section_div input {
  width: 9rem;
  border: none;
  box-shadow: 0px 0px 5px lightgray;
  padding: 0.1rem 0.5rem;
  border-radius: 5px;
}

.view_order_detail_inner_body_inner_section_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.main_add_quantity_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  border: 2px solid #0a74b9;
  border-radius: 100px;
  padding: 0.2rem 0.5rem;
}

.main_add_quantity_div div {
  color: #0a74b9;
}

.main_add_quantity_div div svg {
  cursor: pointer;
}

.view_order_detail_inner_body_inner_section_heading_div {
  font-size: 1.1rem;
  font-weight: 500;
}

.view_order_detail_inner_body_payment_section_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.view_order_detail_inner_body_payment_section_div div label {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
  padding: 0.3rem 1rem;
  box-shadow: inset 0px 0px 5px lightgray;
  border-radius: 100px;
}

.main_order_details_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 1rem;
}

.main_order_payment_details_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 0.5rem;
}

.main_order_payment_details_inner_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.main_proceed_to_checkout_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main_proceed_to_checkout_div button {
  width: 50%;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 20px;
  border: none;
  color: white;
  background: rgb(21, 51, 115);
  background: linear-gradient(
    360deg,
    rgba(21, 51, 115, 1) 0%,
    rgba(104, 169, 217, 1) 100%
  );
  padding: 0.7rem 0;
  /* margin-top: 4rem; */
}

.main_modal_div:focus-visible {
  outline: none;
}

.main_modal_div {
  width: 700px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 24px lightgray;
  border-radius: 10px;
  padding: 1rem;
  padding-bottom: 1rem;
  max-height: 90vh;
  overflow-y: auto;
}

.radio-container {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.custom-radio {
  appearance: none;
  -webkit-appearance: none; /* For Safari */
  width: 15px;
  height: 15px;
  border: 2px solid #0a74b9;
  padding: 2px; /* Blue outline */
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}

.custom-radio:checked {
  /* background-color: #195389; Fill circle when checked */
  border: 3px solid #0a74b9;
}

.custom-radio:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  background-color: #0a74b9; /* White center dot */
  border-radius: 50%;
}

/* Ipad Media Query Start Here */

@media only screen and (max-width: 850px) {
  .view_order_detail_inner_header_left_div {
    width: 70%;
    gap: 0.5rem;
  }

  .view_order_detail_inner_header_right_div {
    width: 25%;
  }

  .view_order_detail_inner_header_right_div img {
    width: 100%;
  }

  .view_order_detail_inner_body_payment_section_div {
    flex-wrap: wrap;
  }

  .main_proceed_to_checkout_div button {
    width: 70%;
  }

  .view_order_detail_inner_body_payment_section_div div label {
    width: 200px;
  }
}

/* Ipad Media Query End Here */

/* Phone Media Query Start Here */

@media only screen and (max-width: 450px) {
  .main_modal_div {
    width: 95%;
  }

  .main_order_detail_div .react-tabs__tab-list {
    flex-direction: column;
    gap: 2rem;
  }

  .view_order_detail_inner_header_div {
    padding: 1rem;
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .view_order_detail_inner_header_left_div {
    width: 100%;
  }

  .view_order_detail_inner_header_right_div {
    width: 100%;
  }

  .view_order_detail_inner_header_right_div img {
    width: 50%;
  }

  .view_order_detail_inner_body_inner_section_heading_div {
    font-size: 0.9rem;
  }

  .view_order_detail_inner_body_inner_section_div {
    width: 100%;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  .view_order_detail_inner_body_inner_section_div input {
    width: 150px;
  }

  .main_proceed_to_checkout_div button {
    width: 100%;
    margin-top: 2rem;
    font-size: 0.9rem;
  }
}

/* Phone Media Query End Here */
