.main-container {
    max-height: 30px;
    background-color: 'yellow';
}

.home-icon {
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.connect-icon {
    height: 50px;
    width: 180px;
    cursor: pointer;
}

.right-side-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bell-icon {
    height: 25px;
    width: 20px;
}

.user-icon {
    margin-left: 10px;
    margin-right: 10px;
    height: 45px;
    width: 45px;
}

.nav-text {
    margin-left: 5px;
    font-size: 25px;
}


.main_header_user_and_icon_holder {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: black;
    padding: 0 1rem;
    transition: background-color 0.3s ease;
}

.header_user_icon {
    color: #195389;
}

.main_header_user_and_icon_holder_active {
    background-color: #195389;
}

.header_location_holder_div a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.header_location_holder_div a:hover {
    text-decoration: none;
}

.header_location_heading_div {
    font-size: 1.1rem;
    font-weight: bold;
    color: #195389;
}

.header_location_div {
    font-size: 1rem;
    color: black;
}

.main_header_setting_div {
    width: 200px;
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 5px lightgray;
    padding: 1rem 0;
    position: absolute;
    right: 2%;
    top: 100%;
    margin-top: .3rem;
    z-index: 1;
    transition: 0.6s ease;
}

.main_header_setting_div hr {
    margin: 0%;
}

.inner_header_setting_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    padding: .5rem 1rem;
    font-weight: 600;
    cursor: pointer;
}

.inner_header_setting_div a {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    color: #195389;
    text-decoration: none;
}

.inner_header_setting_div a:hover {
    text-decoration: none;
}

.inner_header_setting_div:hover {
    background-color: #FCCC2E;
}

.inner_header_setting_div div svg {
    font-size: 1.2rem;
}

/* Phone Media Query Start Here */

@media only screen and (max-width: 450px) {}

/* Phone Media Query End Here */

/* Ipad Media Query Start Here */

@media only screen and (max-width: 850px) {}

/* Ipad Media Query End Here */