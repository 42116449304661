/* UrgentDCModal.module.css */
.urgentDC_PopUp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.urgentDC_PopUp_Container {
  position: relative;
  max-width: 450px;
  max-height: 550px;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 35px;
  background-color: white;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 5px;
}

@media (max-width: 1080px) {
  .urgentDC_PopUp_Container {
    max-width: 350px; /* Slightly more responsive on small screens */
    /* max-height: 350px; Slightly more responsive on small screens */
  }

  .closeBtn {
    top: 5px;
    right: 20px; /* Adjusted position for smaller screens */
  }
}

@media (max-width: 786px) {
  .urgentDC_PopUp_Container {
    max-width: 300px; /* Slightly more responsive on small screens */
    /* max-height: 350px; Slightly more responsive on small screens */
  }

  .closeBtn {
    top: 5px;
    right: 20px; /* Adjusted position for smaller screens */
  }
}

@media (max-width: 600px) {
  .urgentDC_PopUp_Container {
    max-width: 350px; /* Slightly more responsive on small screens */
    max-height: 500; /* Slightly more responsive on small screens */
  }

  .closeBtn {
    top: 5px;
    right: 20px; /* Adjusted position for smaller screens */
  }
}
