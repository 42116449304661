/* UrgentDCModal.module.css */
.urgentDC_PopUp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.urgentDC_PopUp_Container {
  position: relative;
  max-width: 450px;
  max-height: 650px;
  background-color: white;
  border-radius: 10px;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: orangered;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.applyText {
  color: #30569d;
  font-size: 25px;
  padding: 15px 20px;
  font-weight: 600;
  text-align: center;
  user-select: none;
}

.voucherCard {
  max-width: 80%;
  margin: auto;
  position: relative;
}

.purchaseText{
  width: 100%;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-weight: 600;
}

.instantSavings{
  font-size: 1.2rem;
  text-align: center;
  width: 80%;
  margin: 20px auto;
  font-weight: 500;
}

@media (max-width: 1080px) {
  .urgentDC_PopUp_Container {
    max-width: 350px; /* Slightly more responsive on small screens */
    /* max-height: 350px; Slightly more responsive on small screens */
  }

  .applyText {
    font-size: 22px;
  }

  .purchaseText{
    font-size: 1.4rem;
  }
  
  .instantSavings{
    font-size: 1.1rem;
  }
}

@media (max-width: 786px) {
  .urgentDC_PopUp_Container {
    max-width: 300px; /* Slightly more responsive on small screens */
    /* max-height: 350px; Slightly more responsive on small screens */
  }

  .applyText {
    font-size: 18px;
  }

  .purchaseText{
    font-size: 1.3rem;
  }
  
  .instantSavings{
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .urgentDC_PopUp_Container {
    max-width: 350px; /* Slightly more responsive on small screens */
    max-height: 500; /* Slightly more responsive on small screens */
  }

  .applyText {
    font-size: 16px;
  }

  .purchaseText{
    font-size: 1.2rem;
  }
  
  .instantSavings{
    font-size: 0.9rem;
  }
}
