* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.main_faq_div {
    width: 100%;
    padding: 1rem 3rem;
}

.inner_faq_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.main_faq_heading_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* Ipad Media Query Start Here */

@media only screen and (max-width: 850px) {}

/* Ipad Media Query End Here */


/* Phone Media Query Start Here */

@media only screen and (max-width: 450px) {
    .main_faq_div {
        padding: 0%;
    }

    .main_faq_heading_div h2 {
        font-size: 1.5rem;
        text-align: center;
    }
}

/* Phone Media Query End Here */