* {
  margin: 0%;
  padding: 0%;
}

body {
  margin: 0%;
  padding: 0%;
}

.pac-container {
  z-index: 9999 !important;
}

input:focus {
  outline: none;
}

.main_section_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.main_body_div {
  width: 100%;
  height: 95vh;
  /* background-color: #214CAB; */
  background: rgb(21, 51, 115);
  background: linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.left_body_div {
  width: 45%;
  padding: 0 1rem;
}

.right_body_div {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.right_body_div p {
  font-size: 3rem;
  font-weight: bolder;
  color: white;
}

.gasscan {
  width: 70%;
}

.blue_logo {
  width: 50%;
}

.playstoreicon {
  width: 40%;
}

.main_footer_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.2rem;
  /* background-color: #4F82BF; */
  background: rgb(21, 51, 115);
  background: linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%);
  color: white;
}

.main_footer_div a {
  text-decoration: none;
  color: white;
}

.main_footer_div p {
  cursor: pointer;
  margin-bottom: 0;
}

.inner_footer_div1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.inner_footer_div1 hr {
  width: 1px;
  height: 20px;
  background-color: white;
}


.ReactModal__Overlay {
  z-index: 9;
}




/*  */
.main_privacy_policy_section_div {
  /* width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999; */
}

.inner_body_privacy_policy_section_div {
  width: 100%;
  height: fit-content;
  /* height: 95vh; */
  /* background-color: #214CAB; */
  /* background: rgb(21, 51, 115); */
  /* background: linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%); */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.main_header_section_div {
  width: 100%;
  height: 5rem;
  background-color: azure;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.main_header_section_div div img {
  width: 30%;
}

.main_login_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
}

.main_login_div svg {
  font-size: 2rem !important;
  font-weight: bolder;
  color: #65A5D7;
}

.main_banner_section_div {
  width: 100%;
  height: 17rem;
  background-image: url(./assets/1\ –\ 6@3x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-size: 4rem;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #153373;
}

.date_section_div {
  width: 97%;
  text-align: start;
  padding: 1rem 0;
  font-size: 1.1rem;
  font-weight: bold;
}

.main_text_privacy_policy_section_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  gap: 2rem;
  padding: 1rem 0;
}

.full_section__text_privacy_policy_section_div {
  width: 97%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px lightgray;
  border-radius: 5px;
}

.left_text_privacy_policy_section_div {
  width: 30%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px lightgray;
  border-radius: 5px;
}

.text_privacy_policy_inner_heading_div {
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 600;
}

.text_privacy_policy_inner_div {
  padding: 1rem;
}

.text_privacy_policy_inner_div div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5rem;
}

.text_privacy_policy_inner_div div ul {
  width: 90%;
}

.text_privacy_policy_inner_div div ul li {
  padding: .2rem;
}

.right_text_privacy_policy_section_div {
  width: 65%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px lightgray;
  border-radius: 5px;
}

.main_account_deletion_div {
  width: 800px;
  max-height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0px 0px 24px gray;
  border-radius: 10px;
  overflow: auto;
}

.first_main_my_profile_div {
  width: 95%;
  padding: 20px;
}

.main_my_profile_div {
  display: flex;
  flex-direction: row;
  gap: 4rem;
}

.main_manage_card_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

/* OTP CSS Start Here */

.otp_input {
  width: 4rem;
  height: 4rem;
  font-size: 2.5rem;
  text-align: center;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
}

.blue_logo_img {
  width: 100%;
}

/* OTP CSS End Here */


@media only screen and (max-width: 450px) {
  .main_account_deletion_div {
    width: 90% !important;
  }

  .main_account_deletion_map_div {
    width: 90% !important;
  }

  .main_manage_card_div {
    flex-direction: column;
  }

  .main_my_profile_div {
    display: flex;
    flex-direction: column;
  }

  .otp_input {
    width: 3rem;
    height: 3rem;
    font-size: 2rem;
  }

  .blue_logo_img {
    width: 70%;
  }

  .first_main_my_profile_div {
    padding: 10px;
  }
}


@media only screen and (max-width: 850px) {
  .main_body_div {
    flex-direction: column;
  }

  .left_body_div {
    width: 100%;
    text-align: center;
  }

  .right_body_div {
    width: 100%;
  }

  .gasscan {
    width: 50%;
  }

  .blue_logo {
    width: 40%;
  }

  .playstoreicon {
    width: 50%;
  }

  .right_body_div p {
    text-align: center;
  }

  .main_text_privacy_policy_section_div {
    flex-direction: column;
    align-items: center;
  }

  .left_text_privacy_policy_section_div {
    width: 95%;
  }

  .right_text_privacy_policy_section_div {
    width: 95%;
  }

  .full_section__text_privacy_policy_section_div {
    width: 95%;
  }

  .main_footer_div {
    width: 100%;
    height: fit-content;
    flex-direction: column;
    gap: 1rem;
    padding: 10px 0;
  }

  .inner_footer_div1 {
    width: 95%;
  }
}