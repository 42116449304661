* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.Resend_button {
    background-color: transparent;
    border: none;
    color: #195389;
    font-weight: bolder;
}

.ReactModal__Content {
    border: 0px solid transparent !important;
    background: transparent !important;
    border-radius: 0px !important;
}

.MainTermsAndConditionsDiv {
    max-height: 450px;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
}

.MainTermsAndConditions_innerheading_Div {
    font-weight: bolder;
    font-size: 1.2rem;
}

.main_account_deletion_map_div {
    width: 600px;
    max-height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0px 0px 24px gray;
    border-radius: 10px;
    overflow: auto;
}

.main_map_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px !important;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    border-radius: 4px;
    padding: 20px;
}

.inner_map_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.main_map_search_input_and_button_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .8rem;
}

.main_map_search_input_and_button_div div {
    width: 100%;
}

.main_map_search_input_and_button_div div input {
    width: 100%;
    border: none;
    padding: .5rem .5rem;
    box-shadow: 0px 0px 5px lightgray;
    border-radius: 100px;
}

.main_map_search_input_and_button_div button {
    width: 100%;
    border: none;
    background-color: #195389;
    color: white;
    padding: .3rem 0;
    border-radius: 100px;
}

.map {
    width: 700px !important;
    height: 600px !important;
}

.main_map_location_div {
    width: 100%;
    height: 15rem;
}






/* Ipad Media Query Start Here */

@media only screen and (max-width: 850px) {}

/* Ipad Media Query End Here */


/* Phone Media Query Start Here */

@media only screen and (max-width: 450px) {
    .main_map_div {
        width: 100%;
    }

    .MainTermsAndConditionsDiv {
        max-height: 350px;
    }

    .main_map_location_div {
        width: 100%;
        height: 20rem;
    }

    .main_map_location_div {
        width: 100%;
        height: 20rem;
    }
}

/* Phone Media Query End Here */